.active-opacity{
    opacity: 0.4;
}

.footer-links figure{
    /* border-top: .3rem solid #F8EDD5; */
    padding: 12px 8px 8px 8px;
}

.animation-bar{
    border-color: #670000 !important;
    /* animation: slide 1s ease-in-out infinite alternate; */
}

/* @keyframes slide {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-1rem);
    }
} */

.order-info-bar{
    padding: 10px 15px;
    color: white;
    background: #7d9a6f;
    border-radius: 5px 5px 0 0;
}
.order-info-bar .order-food-label{
    text-align: center;
}
.order-food-info-desc{
    white-space: nowrap;
    /* animation: scroll-left 18s linear infinite; */
}

.scrolling-text-container {
    width: 100%;
    overflow: hidden;
  }

  
.footer-wrapper {
    width: 100%;
    margin-top: auto;
    background-color: #000;
}

.footer-wrapper .footer-links {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
}

.footer-wrapper .footer-links figure {
    text-align: center
}

.footer-wrapper .footer-links figure img {
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center bottom;
    object-position: center bottom
}

@media only screen and (max-width: 576px) {
    .footer-wrapper {
    	position: fixed;
        /* position: sticky; */
    	bottom: 0;
	}
}

.footer-wrapper .footer-links figure figcaption {
    color: #fff;
    font-size: 10px;
    font-weight: 800
}

@media only screen and (max-width: 576px) {
    .footer-wrapper .footer-links figure figcaption {
        font-size: 8px
    }
}

@keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }