.login-container {
  width: 100%;
  /* height: 100%; */
  position: relative;
  display: flex;
  flex-flow: column;
  /* margin-bottom: 70px; */
}
.login-section {
  margin-bottom: 10px;
}
.login-container .logo-container {
  padding: 20px;
  text-align: center;
  color: #650000;
}

.login-container .logo {
  width: 50px;
}
.banner-box {
  text-align: center;
  margin: 15px;
}

/* .login-container h2 {
  height: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 24px;
  color: #670000;
  margin-top: 16px;
  } */
.banner-wrapper {
  position: relative;
  text-align: center;
  }
  .banner-wrapper p {
    padding-bottom: 10px;
  }
  .waitList-msg{
    text-align: center;
  }
  .container-title{
  height: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #000;
  margin: 20px 10px;
}
.login-box {
  position: relative;
  /*min-height: 280px;*/
  /*background-color: #f8edd5;*/
}
.login-otp {
}
.login-sign-up-box,
.edit-box {
}
.edit-box {
}
.login-box .login-bg-img {
  /*position: absolute;*/
  width: 100%;
  /*height: 100%;*/
}
.login-sign-up-box .login-bg-img,
.edit-box .login-bg-img {
  height: auto;
}
.login-content {
  /*position: absolute;*/
}
.login-sign-up-box .login-content,
.edit-box .login-content {
  padding-bottom: 0px;
}
.login-box .back-btn-wrapper {
  position: absolute;
  left: 15px;
  top: 10px;
}
.container-text {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #aaaaaa;
  margin: 10px 0;
}
.login-title {
  height: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #000;
  text-transform: uppercase;
}
.login-box .login-title {
  padding-left: 20px;
  margin: 20px;
  padding-top: 10px;
}
.login-sign-up-box .login-title {
  padding-left: 10px;
}
.edit-box .login-title {
  padding-left: 10px;
}
.login-sign-up-box .login-title,
.edit-box .login-title {
}
.login-box .container-text {
  padding-left: 20px;
  margin-bottom: 0px;
}
.login-sign-up-box .container-text {
  padding-left: 10px;
}
.login-container p {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #aaaaaa;
  margin: 10px;
}

.login-container label {
  display: flex;
  flex-direction: column;
  margin: 0.2rem 2rem;
  font-size: 1rem;
  color: #000;
}

/* .login-container input.login-phone {
  width: auto;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  margin-top: 0.4rem;
  padding: 0.4rem 1rem 0.6rem;
  padding-bottom: 30px; // comment this
  } */
  
  .login-container input.login-phone {
  width: calc(100% - 20px);
  height: 40px;
  background: #ffffff;
  border-radius: 0px;
  border: none;
  color: #670000;
  font-style: normal;
  font-weight: 600;
  font-family: Nunito;
}
.login-container input.login-phone::placeholder {
  color: #aaaaaa;
  font-size: 1rem;
}

.login-options {
  margin-bottom: 20px;
}
.login-options p {
  color: #650000;
  margin-top: 12px;
  display: block;
  width: fit-content;
  text-decoration: underline;
  margin: auto;
}

p.checkout-error-msg {
  color: red;
  margin: 0 2rem;
  padding: 1rem;
  background: rgba(255, 0, 0, 0.1);
  border-radius: 0.6rem;
  line-height: normal;
}

  .Td-head{
    margin-bottom: 18px;
  }
  .Td-head p{
    color: #575252;
  margin-top: 12px;
  display: block;
  width: fit-content;
  text-decoration: underline;
  margin: auto;
  }
  p.error-msg {
  color: red;
  text-align: center;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.login-input .select-input select {
  display: block;
  width: fit-content;
  background-color: #eeba51;
  color: #fff;
  height: 40px;
  margin: 10px 0;
}
.login-input .select-input {
  display: flex;
}
.login-input .select-input input {
  width: calc(100% - 90px);
}

.img-box {
  display: flex;
  width: 64px;
  height: 64px;
  background: linear-gradient(
      317.7deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 105.18%
    ),
    #670000;
  background-blend-mode: soft-light, normal;
  box-shadow: 1px 1px 2px #a6abbd;
  border-radius: 5px;
  text-align: center;
  vertical-align: middle;
}

.img-box img {
  margin: auto;
}

.button-text {
  width: auto;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  float: left;
  margin-right: 5%;
}
.button-box {
  display: flex;
  justify-content: center;
}
.btn-login {
  position: relative;
  /* text-transform: uppercase; */
  background-color: #670000;
  color: #fff;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-weight: 700;
  width: 90%;
  text-align: center;
  outline: none;
  border-radius: 100px;
}
.btn-install-button{
  width: 45%;
  display: flex;
  column-gap: 15px;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
}
.skip-box {
  margin: 20px 0;
}
.skip-link {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #686b78;
}

.conditions-box {
  margin: 10px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #93959f;
}

.conditions-box a {
  color: #686b78;
}

.button-panel .button-wrap {
  width: fit-content;
  margin: auto;
}

.login-container div.button-panel {
  width: 100%;
  /* margin: 16px auto 0; */
  margin: 16px auto 10px;
}

/* .login-container div.otp-wrapper div.button-panel {
  width: 67%;
  margin: auto;
  margin-top: 45px;
  }
   */
.otp-wrapper {
  /*position: fixed;*/
  width: 100%;
  /*height: 45%;*/
  bottom: 2px;
  background: #fff;
  /*border-radius: 30px;*/
  /*box-shadow: 0 19px 38px rgb(0 0 0 / 80%), 0 15px 12px rgb(0 0 0 / 50%);*/
  z-index: 1;
}

.enter-otp-msg {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* display: flex; */
  /* align-items: center; */
  color: #aaaaaa;
  /* margin: 0px 20px 10px 20px; */
  text-align: center;
  margin: auto;
}

.otp-input {
  display: flex;
  margin: 10px 20px 0px 20px;
}
.otp-input input {
  border-radius: 0 !important;
  max-width: 50px;
  margin: 10px auto !important;
  cursor: pointer;
  border: none !important;
  border-bottom: 1px solid !important;
}

.otp-input div div {
  margin: 10px;
}

.otp-input div div input.otp-char {
  background: linear-gradient(
      317.7deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 105.18%
    ),
    #f8edd5;
  background-blend-mode: soft-light, normal;
  box-shadow: 1px 1px 2px #a6abbd;
  border: none;
}

.partitioned {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(
    to left,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
}

.button-panel {
  display: flex;
  justify-content: center;
}

.button-panel .proceed-btn {
  display: flex;
  background-color: #670000;
  padding: 1rem 4rem;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  margin: 35px 20px 0px 20px;
  color: #fff;
}
.disabled {
  opacity: 0.5;
}

.login-input {
  width: 70%;
  margin: 0 auto;
  text-align: end;
  position: relative;
}

.modal-top-section {
  position: relative;
  text-align: center;
}
.modal-top-section .colse-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #eaa315;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.modal-top-section .colse-btn img {
  height: 18px;
}

.loader_wrapper {
  text-align: center;
  margin: auto;
}

.checkout-option .bottom-button {
  width: 100%;
  background-color: #670000;
  margin: 20px 0;
  height: 100%;
  border-radius: 40px;
  outline: none;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  transition: all 0.2s;
  position: relative;
  
}
.button--loading .bottom-button {
  visibility: hidden;
  opacity: 0;
}

.button--loading{
  opacity: 0.5;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}


.checkout-option .bottom-button p {
  font-size: 18px;
  text-align: center;
  color: white;
  vertical-align: middle;
  display: table-cell;
  padding-bottom: 0px;
}

.disabled-link {
  pointer-events: none;
}

.checkout-option.active {
  bottom: 0;
  height: 50px;
  width: 100%;
  max-width: 200px;
  margin: auto;
}

.login-container div.mt {
  margin-top: 70px;
  padding: 1rem;
}

.mg-10 {
  margin: 10px;
}
.pg-10 {
  padding: 10px;
}
.opt-close-btn {
  margin-top: 25px;
  margin-left: 15px;
}
.material-form-field {
  width: 100%;
}
.material-form-field input {
  border: none !important;
  margin-bottom: 0px !important;
  color: #000 !important;
}
.form-box {
  padding: 20px;
}
.mt-30 {
  margin-top: 30px;
}
label.Mui-focused {
  color: #7e808c !important;
}
.MuiInput-underline:after {
  border-bottom-color: #7e808c !important;
}
.back-banner-btn {
  position: absolute;
  z-index: 99;
}
.resend-otp {
  color: black;
  text-align: center;
  border-bottom: 1px solid;
}

.resend_otp-wrapper {
  text-align: center;
  margin-top: 30px;
}
.login-icon {
  position: absolute;
  width: 30px;
  right: 10px;
  top: 15px;
}

.checkout-container .payon_delivery-container {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.payon_delivery-container {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: auto;
  align-items: center;
  margin-bottom:5rem;
}

.payon_delivery-container h6 {
  font-weight: bold;
  width: 60%;
}

.signup-progress{
  display: block;
  width: 80%;
  height: 10px;
  margin: auto;
}

progress.signup-progress::-webkit-progress-bar{
  background-color: #aaaaaa;
}

progress.signup-progress::-webkit-progress-value {
  background-color: #670000;
}

.signup-progress-container{
  margin-bottom: 20px;
}
.signup-progress-container p{
  margin: 10px auto;
  text-align: center;
  padding-bottom: 0px;
}

.disabled {
  opacity: 0.5;
}

div.payon_delivery-container input[type='checkbox'] {
  position: inherit;
  opacity: 1;
  width: 30px;
  pointer-events: inherit;
}

p.pay-info{
  margin: 0px;
}