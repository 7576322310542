.order-history_container {
  text-transform: capitalize;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 1.2rem;
}

.bd-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.8rem;
}

.bd-bottom-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  color: #666666;
  column-gap: 8px;
}

.order-history_right {
  display: flex;
  justify-content: flex-start;
}

.order-history_viewbutton{
  background-color: #f8edd5;
  height: 20px;
  line-height: 20px;
  border-radius: 4.5rem;
  box-shadow: 0px 2px 4px 0px #00000040;
  padding: 0;
  width: 115px;
  position: relative;
  font-size: 14px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  letter-spacing: .5px;
  transition: background-color .2s ease-out;
  cursor: pointer;
  border: none;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 20px;
}

.order-history_viewbutton span{
  font-size: 11px;
    text-transform: uppercase;
    line-height: 20px;
    color: #670000;
}

.order-history_left {
  margin-right: 1rem;
}
.order-history_right{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.order-history_left_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.order-history_left_header_status {
  border: 2px solid #7d9a6f;
  color: #7d9a6f;
  padding: 2px;
  border-radius: 8px;
  line-height: 17px;
  font-size: 13px;
  text-align: center;
}

.review-label-link{
  text-transform: initial;
  color: #670000de;
  text-decoration: underline;
  font-size: 12px;
}

.review-type-label-link{
  font-size: 12px;
}

.order-history_left h1 {
  font-size: 16px;
  color: black;
}

.order-history_left p {
  font-size: 14px;
  color: #666666;
  padding-bottom: 0.5rem;
}

.order-history_schedule {
  display: flex;
  background-color: #670000;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  padding-inline: 15px;
}
.order-history_schedule img {
  width: 15px;
}

.order-history_btn-schedule {
  all: unset;
  color: white;
  margin-left: 0.5rem;
}
