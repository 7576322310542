.order_status-badge {
  padding: 12px;
  z-index: 1;
  color: white;
  background: #7d9a6f;
  font-weight: bold;
  position:fixed;
  bottom: 4.1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
