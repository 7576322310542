.review-box-wrapper{
    margin: 15px 0;
    display: flex;
    column-gap: 10px;

}
.review-positive-button{
    background: #fff;
    border: 2px solid green;
    border-radius: 4px;
    padding: 3px 12px;
    color: green;
}
.review-positive-button-active{
    background: green !important;
    color: #fff !important;
}
.review-custom-label-active{
    background: #dd9d4a !important;
    color: #fff !important;
}
.review--nonpositive-button-active{
    background: red !important;
    color: #fff !important;
}
.review-custom-label-button{
    background: #fff;
    border: 2px solid #dd9d4a;
    border-radius: 4px;
    padding: 3px 12px;
    color: #dd9d4a;
}
.review--nonpositive-button{
    background: #fff;
    border: 2px solid red;
    border-radius: 4px;
    padding: 3px 12px;
    color: red;
}
.review-positive-button:focus, .post-review-button:focus, .review-custom-label-button:focus, .review--nonpositive-button:focus{
    background: #fff;
}
.review-food-textarea{
    height: 120px;
    resize: vertical;
    border: 1px solid #8080808f;
    padding: 8px;
}
.post-review-button-wrapper{
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.post-review-button{
    padding: 10px 40px;
    font-size: 16px;
    background: #fff;
    border-radius: 7px;
    border: 1px solid #000;
}