.deliverySlotConfirmBtn{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 14px 16px;
    color: rgb(255, 255, 255);
    background-color: #7d9a6f;
    width: 100%;
    border: 0px;
    border-radius: 0px;
}
.deliverySlotConfirmBtn:disabled{
    opacity: 0.3;
}
.deliveryTimeBoxWrapper{
    margin: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.deliveryTimeSlotSelector{
    margin: 0px 16px 16px 16px;
    display: grid;
    grid-template-columns: 40% 60%;
}
.deliveryTimeSlotSelect .ant-select-selector{
    border: 0px !important;
}
.deliveryTimeSlotSelectPopup{
    z-index: 10000000;
}