.portionSelectorBox {
    margin: 20px 0px 20px 0px;
    background-color: #8080801a;
    border-radius: 7px;
}

.portionCheckSelect {
    position: relative;
    opacity: 1;
}

.portionCheckSelectLabel {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.portionViewSelectorBox {
    padding: 15px 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dishInfoPortionSelector {
    padding: 18px;
    background-color: #80808033;
    margin: 0px -15px 0px -15px;
}

.dishInfoPortionSelectorHeader {
    text-transform: capitalize;
    font-size: 16px;
}

.portionCheckSelect .form-check-input {
    opacity: 1;
    position: relative;
    top: 2px;
}

.dishInfoPortionSelectorQuantityHeader {
    padding: 15px 17px;
    border-bottom: 1px solid #80808080;
}
