.explore-swiper-wrapper .swiper-wrapper{
    height: calc(100vh - 58px) !important
}
.splash-screen{
    width: 100%;
    height: 100vh;
    background-color: papayawhip;
}

.splash-screen img {
    width: 64px;
    display: block;
    position: absolute;
    transform: translateY(-50%);
    transform: translateX(-50%);
    top: 50%;
    left: 50%;
}
.veg-choice-wrapper{
    max-width: 100vw;
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
}
